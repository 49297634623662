
import React from 'react'
import {
	Switch,
} from 'react-router-dom'
import { RouteWithLayout, RouteWithOutAuthLayout } from './layout/components'

import {  AntDesignLayout, AuthLayout } from './layout'

import Login from './pages/Login'

const HomePage = React.lazy(() => import('./pages/HomePage'))
const Products = React.lazy(() => import('./pages/Products'))
const Order = React.lazy(() => import('./pages/Order'))
const Orders = React.lazy(() => import('./pages/Orders'))
const Customers = React.lazy(() => import('./pages/Customers'))
const Staffs = React.lazy(() => import('./pages/Staffs'))
const StaffPayments = React.lazy(() => import('./pages/StaffPayments'))
const SalesReport = React.lazy(() => import('./pages/SalesReport'))
const Lines = React.lazy(() => import('./pages/Lines'))
const ItemList = React.lazy(() => import('./pages/ItemList'))
const ShopBill = React.lazy(() => import('./pages/ShopBill'))

const PageNotFound = () => {
	return (
		<div>
			<h3>Page not found!</h3>
		</div>
	)
}

const Routes = () => {
	return (
		<Switch>
			<RouteWithLayout
				component={HomePage}
				exact
				layout={AntDesignLayout}
				path="/"
			/>
			<RouteWithLayout
				component={Products}
				exact
				layout={AntDesignLayout}
				path="/products"
			/>
			<RouteWithLayout
				component={Order}
				exact
				layout={AntDesignLayout}
				path="/order"
			/>
			<RouteWithLayout
				component={Orders}
				exact
				layout={AntDesignLayout}
				path="/orders"
			/>
			<RouteWithLayout
				component={Customers}
				exact
				layout={AntDesignLayout}
				path="/customers"
			/>
			<RouteWithLayout
				component={Staffs}
				exact
				layout={AntDesignLayout}
				path="/staffs"
			/>
			<RouteWithLayout
				component={Lines}
				exact
				layout={AntDesignLayout}
				path="/lines"
			/>
			<RouteWithLayout
				component={StaffPayments}
				exact
				layout={AntDesignLayout}
				path="/staff-payments"
			/>
			<RouteWithLayout
				component={SalesReport}
				exact
				layout={AntDesignLayout}
				path="/sales-report"
			/>
			<RouteWithLayout
				component={ItemList}
				exact
				layout={AntDesignLayout}
				path="/item-list"
			/>
			<RouteWithLayout
				component={ShopBill}
				exact
				layout={AntDesignLayout}
				path="/shop-billing"
			/>
			<RouteWithOutAuthLayout
                component={Login}
                layout={AuthLayout}
                path="/auth/login"
            />
            <RouteWithLayout
                layout={AuthLayout}
                component={PageNotFound}
            />
		</Switch>
	)
}


export default Routes;