import React, { Suspense, useState } from 'react'
import { Layout, Row, Col, Dropdown, Menu } from 'antd';
import { Sidebar } from './components'
import { useAuth } from 'context/auth-context'
import { 
	MenuUnfoldOutlined,
	MenuFoldOutlined,
	DownOutlined,
	PoweroffOutlined
} from '@ant-design/icons'

const { Header, Content } = Layout

const AntDesignLayout = (props) => {
	const { children } = props
	const { logout } = useAuth()

	const [collapsed, setCollapsed] = useState(false)

	const toggleCollapsed = () => {
		setCollapsed(!collapsed)
	}

	const handleMenuClick = (e) => {
		if (e.key === 'logout') {
			logout()
		}
	}

	const menuDropdown = (
		<Menu onClick={handleMenuClick}>
			<Menu.Item key="logout">
				<PoweroffOutlined /> Logout
			</Menu.Item>
		</Menu>
	)

	return (
		<Layout style={{ minHeight: '100vh' }}>
			<Sidebar collapsed={collapsed} />
			<Layout className="site-layout">
				<Header style={{ padding: 10, backgroundColor: '#ffffff' }}>
					{React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
						className: 'trigger',
						onClick: toggleCollapsed,
					})}
					<Row justify="end">
						<Col span={3}>

							<div className="user-dropdown" style={{ paddingRight: '10px', textAlign: 'center' }}>
								<Dropdown overlay={menuDropdown}>
									<div>
										User <DownOutlined />
									</div>
								</Dropdown>
							</div>

						</Col>
					</Row>
				</Header>
				<Content style={{ margin: '0 16px' }}>
					<div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
						<Suspense fallback={<div>Loading...</div>}>
							{children}
						</Suspense>
					</div>
				</Content>
			</Layout>
		</Layout>
	)
}

export default AntDesignLayout

