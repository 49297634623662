import React from 'react'
import { Link } from 'react-router-dom'
import { Layout, Menu } from 'antd'

const { SubMenu } = Menu
const { Sider } = Layout


const Sidebar = (props) => {
	const {collapsed} = props
	return (
		<Sider
			breakpoint="lg"
			collapsedWidth="0"
			collapsed={collapsed}
		>
			<div className="logo">
				<Link to={"/"}>
					Mayer doa
				</Link>
			</div>
			<Menu theme="dark" defaultSelectedKeys={['1']} mode="inline">
				<SubMenu key="manage-order" title="Manage orders" >
					<Menu.Item key="order">
						<Link to="/order" >Create order</Link>
					</Menu.Item>
					<Menu.Item key="orders">
						<Link to="/orders" >All order</Link>
					</Menu.Item>
					<Menu.Item key="product-item-list">
						<Link to="/item-list" >Item list</Link>
					</Menu.Item>
					<Menu.Item key="shop-billing">
						<Link to="/shop-billing" >Billing</Link>
					</Menu.Item>
				</SubMenu>

				<SubMenu key="products" title="Manage services" >
					<Menu.Item key="services">
						<Link to="/products" >Services</Link>
					</Menu.Item>
				</SubMenu>

				<SubMenu key="manage_customers" title="Manage Shops" >
					<Menu.Item key="customers">
						<Link to="/customers" >Shops</Link>
					</Menu.Item>
				</SubMenu>
				<SubMenu key="manage_staffs" title="Manage Staff" >
					<Menu.Item key="staffs">
						<Link to="/staffs" >Staff</Link>
					</Menu.Item>
				</SubMenu>
				<SubMenu key="manage_lines" title="Manage lines" >
					<Menu.Item key="lines">
						<Link to="/lines" >Lines</Link>
					</Menu.Item>
				</SubMenu>
				<SubMenu key="manage_payments" title="Manage payments" >
					<Menu.Item key="payments">
						<Link to="/staff-payments" >Staff payments</Link>
					</Menu.Item>
				</SubMenu>
				<SubMenu key="manage_reports" title="Manage reports" >
					<Menu.Item key="reports">
						<Link to="/sales-report" >Sales report</Link>
					</Menu.Item>
				</SubMenu>
			</Menu>
		</Sider>
	)
}

export default Sidebar
