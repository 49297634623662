import axios from 'axios'
import { LOCAL_STORAGE_KEY } from 'consts/storage'

axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem(LOCAL_STORAGE_KEY)

var api_url = process.env.REACT_APP_DEV_API_URL

if (process.env.NODE_ENV === 'production') {
	api_url = process.env.REACT_APP_PROD_API_URL
}

const instance = axios.create({
	baseURL: api_url,
})

export default instance