import React, { useLayoutEffect } from 'react'
import { bootstrapAppData } from '../utils/bootstrap'
import * as authClient from '../utils/auth-client'
import { LOCAL_STORAGE_USER } from 'consts/storage'
import { useQuery } from 'react-query'

const AuthContext = React.createContext()

function AuthProvider(props) {
	const { isFetching, data } = useQuery('user', bootstrapAppData)

	useLayoutEffect(() => {
		if (!isFetching) {

			if (data) {
				window.localStorage.setItem(LOCAL_STORAGE_USER, JSON.stringify(data.user))
			}
		}
	}, [isFetching, data])

	const login = form => authClient.login(form)
	const register = form => authClient.register(form).then(() => {
		window.location.reload()
	})
	const logout = () => authClient.logout()
	const loggedIn = () => authClient.loggedIn()

	return (
		<AuthContext.Provider value={{ data, login, loggedIn, logout, register }} {...props} />
	)
}

function useAuth() {
	const context = React.useContext(AuthContext)
	if (context === undefined) {
		throw new Error(`useAuth must be used within a AuthProvider`)
	}
	return context
}

export { AuthProvider, useAuth }