import React, { useState } from 'react'
import { Form, Input, Button, Card, Row, Col, Alert } from 'antd'
import { UserOutlined, LockOutlined } from '@ant-design/icons'
// import { ReactComponent as Logo } from 'assets/logo.svg'
import { useAuth } from 'context/auth-context'
import { LOCAL_STORAGE_KEY, LOCAL_STORAGE_USER } from 'consts/storage'
import { Link } from "react-router-dom";
import './Login.css'

const ReLogin = () => {
	const { login } = useAuth()
	const [isLoading, setIsLoading] = useState(false)
	const [errors, setErrors] = useState(null);
	const onSubmit = (values) => {
		setIsLoading(true)
		login(values)
			.then((res) => {
				setIsLoading(false)
				window.localStorage.setItem(LOCAL_STORAGE_KEY, res.data.token)
				window.localStorage.setItem(LOCAL_STORAGE_USER, JSON.stringify(res.data.user))
				window.location.reload()
			}).catch(err => {
				setIsLoading(false)
				setErrors(err.response);
			})
	}
	return (
		<Row justify="space-around" align="middle">
			<Col xs={20} md={4}>
				<div style={{
					textAlign: 'center',
					padding: '30px',
					marginTop: '50px',
					marginBottom: '20px'
				}}
				>
					<Link to={"/auth/login"}>
						Mayer doa
					</Link>
				</div>

				<Card>
					<Form
						name="normal_login"
						className="login-form"
						onFinish={onSubmit}
					>
						<Form.Item
							name="email"
							rules={[
								{
									required: true,
									message: 'Please input your Email!',
								},
							]}
						>
							<Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Email" />
						</Form.Item>
						<Form.Item
							name="password"
							rules={[
								{
									required: true,
									message: 'Please input your Password!',
								},
							]}
						>
							<Input
								prefix={<LockOutlined className="site-form-item-icon" />}
								type="password"
								placeholder="Password"
							/>
						</Form.Item>
						<Form.Item>
							<Button
								type="primary"
								htmlType="submit"
								className="login-form-button"
								loading={isLoading}
								block
							>
								Log in
							</Button>
						</Form.Item>
					</Form>
					{
						errors && errors.data && errors.data.error &&
						<Alert message={errors.data.error} type="error" showIcon />
					}

				</Card>
			</Col>
		</Row>
	);
};

export default ReLogin
