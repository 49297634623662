import client from './api-client'
import decode from 'jwt-decode';

import { LOCAL_STORAGE_KEY, LOCAL_STORAGE_USER } from 'consts/storage'

function handleUserResponse(res) {
	window.localStorage.setItem(LOCAL_STORAGE_KEY, res.data.access_token)
}

function getUser() {
	const token = getToken()
	if (!token) {
		return Promise.resolve(null)
	}
	return client.get('/profile/me').catch(error => {
		// logout()
		return Promise.reject(error)
	})
}

function login({ email, password, remember_me }) {
	return client.post('/login', { email, password, remember_me })
}

function loggedIn() {
	const token = getToken()
	return !!token && !isTokenExpired(token)
}

function isTokenExpired(token) {
	try {
		const decoded = decode(token);
		if (decoded.exp < Date.now() / 1000) {
			return true;
		} else return false;
	}
	catch (err) {
		return false;
	}
}

function register({ username, password }) {
	return client.post('register', { username, password }).then(
		handleUserResponse,
	).catch(err => { console.log(err) })
}

function logout() {
	window.localStorage.removeItem(LOCAL_STORAGE_KEY)
	window.localStorage.removeItem(LOCAL_STORAGE_USER)
	window.location.reload()
}

function getToken() {
	return window.localStorage.getItem(LOCAL_STORAGE_KEY)
}

export { login, loggedIn, isTokenExpired, register, logout, getToken, getUser }