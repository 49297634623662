import React, { useReducer, useLayoutEffect } from 'react'
import { useAuth } from './auth-context'
import { LOCAL_STORAGE_USER } from 'consts/storage'

const UserContext = React.createContext()

function UserProvider(props) {
	const { data } = useAuth()
	const [state, dispatch] = useReducer((state, action) => {
		switch (action.type) {
			case 'UPDATE_USER':
				if(state.user) {
					state.user = { ...state.user, ...action.payload }
					window.localStorage.setItem(LOCAL_STORAGE_USER, JSON.stringify(state.user))
				}
				return state
			case 'set_user':
				if(data && data.user) {
					state.user = data.user
					return state
				}
				return {user: null}
				
			default:
				throw new Error()
		}
	}, data)

	useLayoutEffect(() => {
		dispatch({ type: 'set_user' })
	}, [data])

	return <UserContext.Provider value={{ state, dispatch }} {...props} />
}

function useUser() {
	const context = React.useContext(UserContext)
	if (context === undefined) {
		throw new Error(`useUser must be used within a UserProvider`)
	}
	return context
}

export { UserProvider, useUser }