import React from 'react'

const AuthLayout = (props) => {
	const { children } = props;
	return (
		<div>
			{children}
		</div>
	)
}

export default AuthLayout